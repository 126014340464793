<template>
    <div>
        <div v-if="template" class="card">
            <h1>Dettagli del template</h1>
            <dl class="">
                <dt class="">Nome</dt>
                <dd class="">{{ template.name }}</dd>

                <dt class="">Prodotto EduLab</dt>
                <dd class="">{{ template.edulabProductId }}</dd>

                <dt class="">Durata della licenza (giorni)</dt>
                <dd class="">{{ template.durationDays }}</dd>

                <dt class="">Attivazione</dt>
                <dd class="">{{ template.activate }}</dd>

                <dt class="">Limite di attivazione (giorni)</dt>
                <dd class="">{{ (template.activationLimitDays > 0) ? template.activationLimitDays : 'Nessun limite' }}</dd>

                <b-row style="background-color: #eeeeee; padding: 10px">
                    <b-col sm="12"><b>Immagini per la tessera</b></b-col>
                    <b-col sm="6">
                        Fronte<br/>
                        <img :src="'data:image/png;base64,'+cardTemplateFront" v-if="cardTemplateFront" class="img-fluid" />
                    </b-col>
                    <b-col sm="6">
                        Retro<br/>
                        <img :src="'data:image/png;base64,'+cardTemplateRetro" v-if="cardTemplateRetro" class="img-fluid" />
                    </b-col>
                </b-row>


                <dt class="">Condizioni generali di utilizzo</dt>

                <dd class="terms-of-use">
                    <div v-html="template.termsOfUse"  :class="{ 'collapsed-terms': (collapseTerms) }" ></div>
                    <p class="text-center" style="margin-bottom: 0">
                        <b-button variant="link" size="sm" @click="toggleCollpaseTerms()" v-if="collapseTerms">
                            <b-icon icon="chevron-compact-down"/> Mostra di più</b-button>
                        <b-button variant="link" size="sm" @click="toggleCollpaseTerms()" v-else>
                            <b-icon icon="chevron-compact-up"/> Mostra di meno</b-button>
                    </p>
                </dd>
            </dl>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <b-button variant="success" size="" @click="generate">
                        <b-icon icon="gear"/>
                        Genera licenze
                    </b-button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="link" size="sm" @click="edit">
                        <b-icon icon="pencil"/>
                        Modifica
                    </b-button>
                </div>
            </div>

        </div>
        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Practice...' }}</p>
        </div>
    </div>
</template>

<script>
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import UploadDataService from "@/components/upload/UploadDataService";
import moment from 'moment';

export default {
    name: "product-license-template-view",

    data() {
        return {
            cardTemplateFront: null,
            cardTemplateRetro: null,
            collapseTerms: true,
            message: '',
            errorMsg: ''
        };
    },
    props: ["template"],
    methods: {
        getTemplate(id) {
            ProductLicenseTemplateDataService.get(id)
                .then(response => {
                    this.template = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        edit() {
            this.$emit('edit-template');
        },
        generate() {
            this.$emit('generate-license');
        },
        closeEditor() {
            this.$emit('closed-editor');
        },
        toggleCollpaseTerms() {
            this.collapseTerms = !this.collapseTerms;
        },
    },
    watch: {
        template: function () {
            if (this.template.cardTemplateFront) UploadDataService.get(this.template.cardTemplateFront)
                .then(response => {
                    this.cardTemplateFront = response.data.encoded;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });

            if (this.template.cardTemplateRetro) UploadDataService.get(this.template.cardTemplateRetro)
                .then(response => {
                    this.cardTemplateRetro = response.data.encoded;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
    },
    mounted() {
        this.message = '';
        if (this.template.cardTemplateFront) UploadDataService.get(this.template.cardTemplateFront)
            .then(response => {
                this.cardTemplateFront = response.data.encoded;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });

        if (this.template.cardTemplateRetro) UploadDataService.get(this.template.cardTemplateRetro)
            .then(response => {
                this.cardTemplateRetro = response.data.encoded;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        //this.getTemplate(this.templateId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
.collapsed-terms {
    height: 140px;
    overflow: hidden;
}
.terms-of-use {
    padding: 15px;
    border: 1px solid #eee;
}
</style>
