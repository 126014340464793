import http from "@/helpers/http-common";
import {store} from "../store"

let EduLabApiBaseURL = '';
if (process.env.VUE_APP_ENV == 'production') {
    EduLabApiBaseURL = 'https://edulab.toccafondimultimedia.com/edulab/'
} else if (process.env.VUE_APP_ENV == 'staging') {
    EduLabApiBaseURL = 'https://dev.bradipon.it/edulab.it_/edulab/'
} else { // development localhost
    //EduLabApiBaseURL = 'https://edulab.toccafondimultimedia.com/edulab/'
    EduLabApiBaseURL = 'https://dev.bradipon.it/edulab.it_/edulab/'
}

class EduLabDataService {
    edulabBaseUrl = process.env.VUE_APP_ENV === 'production'
        ? 'https://edulab.toccafondimultimedia.com/'
        : 'https://dev.bradipon.it/edulab.it_/';
    eduLabApiBaseURL = EduLabApiBaseURL;

    getAllProducts() {
        return fetch(this.eduLabApiBaseURL + "products");
    }

    getOnline() {
        let query = this.createQuery();
        return fetch(this.eduLabApiBaseURL + `online?${query}`);
    }

    getLogged(filter) {
        let query = this.createQuery(filter);
        return fetch(this.eduLabApiBaseURL + `logged?${query}`);
    }

    getUniqueAccess(filter) {
        let query = this.createQuery(filter);
        return fetch(this.eduLabApiBaseURL + `unique_access?${query}`);
    }

    getRegistered(filter) {
        let query = this.createQuery(filter);
        return fetch(this.eduLabApiBaseURL + `registered?${query}`);
    }

    getProductAccess(filter) {
        let query = this.createQuery(filter);
        return fetch(this.eduLabApiBaseURL + `product_access?${query}`);
    }

    getAppStats() {
        let query = this.createQuery();
        return fetch(this.eduLabApiBaseURL + `app_stats?${query}`);
    }

    createQuery(filter = null) {
        let query = '';
        if (filter) {
            for (const property in filter) {
                if (filter[property]) {
                    if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                    else query += `${property}=${filter[property]}&`
                }
            }
        }
        // add edugest token
        query += `edugest_token=${store.state.token}`;
        return query;
    }
}

export default new EduLabDataService();
