<template>
    <div class="card">
        <div v-if="template.cardTemplateFront && template.cardTemplateRetro">
            <h3>Genera nuove licenze</h3>
            <div v-if="!submitted">
                <b-form @submit.stop.prevent="save">

                    <b-form-row>
                        <b-col sm="8">
                            <b-form-group id="schoolId" label="Scuola" label-for="schoolId" description="">
                                <b-form-select v-model="item.schoolId"
                                               :state="validateState('schoolId')"
                                                @change="previewCard()">
                                    <option value="0">- Scegli una scuola -</option>
                                    <option v-for="school in schools" v-bind:value="school.id" :key="school.id">
                                        {{ school.name }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback
                                    id="schoolId-live-feedback"
                                >Questo campo è obbligatorio.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group id="qty" label="Quantità" label-for="qty" description="">
                                <b-form-input v-model="item.qty"
                                              placeholder=""
                                              :state="validateState('qty')"
                                              aria-describedby="qty-live-feedback"></b-form-input>
                                <b-form-invalid-feedback
                                    id="qty-live-feedback"
                                >Questo campo è obbligatorio.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                </b-form>

                <div class="card-preview-container">
                    <h5>Anteprima delle tessere personalizzate</h5>
                    <p>Per modificare l'intestazione, modificare la scuola e caricare i due png per la testata anteriore e posteriore.</p>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <h4>FRONTE</h4>
                            <div class="card-preview" :style="{ backgroundImage: 'url(data:image/png;base64,' + cardPreview.bgFront + ')' }">
                                <div class="card-preview-header" v-if="cardPreview.hFront"
                                     :style="{ backgroundImage: 'url(data:image/png;base64,' + cardPreview.hFront + ')' }"></div>
                                <div class="card-preview-body"></div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h4>RETRO</h4>
                            <div class="card-preview" :style="{ backgroundImage: 'url(data:image/png;base64,' + cardPreview.bgRetro + ')' }">
                                <div class="card-preview-header" v-if="cardPreview.hRetro"
                                     :style="{ backgroundImage: 'url(data:image/png;base64,' + cardPreview.hRetro + ')' }"></div>
                                <div class="card-preview-body"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="action-bar row">
                    <div class="col-sm-4">
                        <button class="btn btn-success " @click="save" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Genera</button>
                    </div>
                    <div class="col-sm-8 ">
                        <p v-if="this.$store.state.loading" style="margin-top: 5px"><img src="@/assets/loader.gif" height="25"> generazione delle licenze e del pdf per la stampa delle tessere</p>
                    </div>
                </div>
                <div>
                    <br>
                    <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>
        </div>
        <div v-else>
            <h3>Attenzione</h3>
            <p>Non è possibile generare licenze per questo template,<br>è necessario caricare le immagini di sfondo della tessera.</p>
            <b-button variant="warning" @click="closeEditor">Chiudi</b-button>
        </div>
    </div>
</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import {required} from 'vuelidate/lib/validators'
import UploadDataService from "@/components/upload/UploadDataService";

export default {
    name: "product-license-generate",
    data() {
        return {
            item: {
                templateId: null,
                schoolId: null,
                qty: null,
            },
            schools: [],
            cardPreview: {
                bgFront: null,
                bgRetro: null,
                hFront: null,
                hRetro: null,
            },
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    props: ['template'],
    validations: {
        item: {
            templateId: {
                required,
            },
            schoolId: {
                required,
            },
            qty: {
                required,
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                const school = this.schools.find(el => el.id == this.item.schoolId);
                //console.log(school);
                ProductLicenseDataService.generate(this.item)
                    .then(response => {
                        if (response) {
                            const blob = new Blob([response.data], { type: "application/pdf" });
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            //link.download = `Stampa ${this.item.qty} tessere ${this.template.name} per ${school.name}`;
                            link.download = `Tessere ${school.name} - ${this.template.name} num ${this.item.qty}`;
                            link.click();
                        }
                        this.submitted = true;
                        this.$emit('generated-licenses');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        previewCard() {
            this.cardPreview.hFront = null;
            this.cardPreview.hRetro = null;
            SchoolDataService.get(this.item.schoolId)
                .then(response => {
                    if (response) {
                        if (response.data.cardHeaderFront) UploadDataService.get(response.data.cardHeaderFront)
                            .then(response => {
                                if (response) this.cardPreview.hFront = response.data.encoded;
                                console.log('school', response.data)
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        if (response.data.cardHeaderRetro) UploadDataService.get(response.data.cardHeaderRetro)
                            .then(response => {
                                if (response) this.cardPreview.hRetro = response.data.encoded;
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    }
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.item.templateId = this.template.id;
        UploadDataService.get(this.template.cardTemplateFront)
            .then(response => {
                if (response) this.cardPreview.bgFront = response.data.encoded;
            })
            .catch(e => {
                console.log(e);
            });
        UploadDataService.get(this.template.cardTemplateRetro)
            .then(response => {
                if (response) this.cardPreview.bgRetro = response.data.encoded;
            })
            .catch(e => {
                console.log(e);
            });
       //
        SchoolDataService.getAll()
            .then(response => {
                this.schools = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style scoped>
.card-preview-container {
    background-color: rgba(150, 150, 150, .2);
    padding: 15px 0;
    min-height: 350px;
    text-align: center;
}
.card-preview {
    display: inline-block;
    width: 247px;
    height: 155px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
}
.card-preview-header {
    width: 247px;
    height: 80px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
}

.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
