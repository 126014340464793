<template>
    <div ref="editor"></div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for snow theme

export default {
    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            editor: null
        };
    },
    mounted() {
        this.editor = new Quill(this.$refs.editor, {
            modules: {
                toolbar: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ['bold', 'italic', 'underline']
                ]
            },
            theme: 'bubble',
            formats: ['bold', 'underline', 'header', 'italic']
        });

        this.editor.root.innerHTML = this.value;

        this.editor.on('text-change', () => this.update());
    },

    methods: {
        update() {
            this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
        }
    }
}
</script>

<style >
.ql-editor {
    border: 1px solid #ced4da!important;
    border-radius: 0.25rem!important;
}
</style>