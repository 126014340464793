<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="save">

                <b-form-group id="edulabProductId" label="Prodotto EduLab" label-for="edulabProductId" description="">
                    <b-form-select v-model="item.edulabProductId">
                        <option value="0">- Scegli un prodotto -</option>
                        <option v-for="product in products" v-bind:value="product.id" :key="product.id">
                            {{ product.name }}
                        </option>
                    </b-form-select>
                </b-form-group>


                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="item.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>



                <b-form-row>
                    <b-col sm="4">
                        <b-form-group id="durationDays" label="Durata della licenza (giorni)" label-for="durationDays">
                            <b-form-input v-model="item.durationDays"
                                          placeholder=""
                                          :state="validateState('durationDays')"
                                          aria-describedby="durationDays-live-feedback"></b-form-input>
                            <b-form-invalid-feedback
                                id="durationDays-live-feedback"
                            >Questo campo è obbligatorio.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col sm="8">
                        <b-form-group id="activate" label="Attivazione" label-for="activate" description="">
                            <b-form-select v-model="item.activate"
                                           :state="validateState('activate')"
                                           aria-describedby="activationLimitDays-live-feedback">
                                <option value="onRegister">Al momento della registrazione</option>
                                <option value="onCreate">Alla creazione</option>
                            </b-form-select>
                            <b-form-invalid-feedback
                                id="activate-live-feedback"
                            >Questo campo è obbligatorio.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-form-row>

                <b-row style="background-color: #eeeeee; padding: 10px">
                    <b-col sm="12"><b>Immagini per la tessera</b></b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setFront($event)" :label="'FRONTE'"/>
                    </b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setRetro($event)" :label="'RETRO'"/>
                    </b-col>
                </b-row>


                <b-form-group id="activationLimitDays" label="Limite di attivazione (giorni)" label-for="activationLimitDays" description="Specificare entro quanti giorni è possibile attivare la licenza. Il valore 0 equivale a nessun limite.">
                    <b-form-input v-model="item.activationLimitDays"
                                  placeholder=""
                                  :state="validateState('activationLimitDays')"
                                  aria-describedby="activationLimitDays-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="activationLimitDays-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="termsOfUse" label="Condizioni generali di utilizzo" label-for="termsOfUse">
                    <editor-quill v-model="item.termsOfUse" />
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="save"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import UploadImage from "@/components/upload/UploadImage";
import EditorQuill from "@/components/utility/EditorQuill";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "product-license-template-create",
    components: { UploadImage, EditorQuill },
    data() {
        return {
            item: {
                id: null,
                name: null,
                durationDays: null,
                activate: null,
                activationLimitDays: null,
                edulabProductId: null,
                termsOfUse: '',
                cardTemplateFront: '',
                cardTemplateRetro: ''
            },
            products: [],
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        item: {
            name: {
                required,
            },
            durationDays: {
                required,
            },
            activate: {
                required,
            },
            activationLimitDays: {
                required,
            },
            edulabProductId: {
                required,
            },
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                ProductLicenseTemplateDataService.create(this.item)
                    .then(response => {
                        this.item.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-template');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        setFront(file) {
            this.item.cardTemplateFront = file.name;
        },
        setRetro(file) {
            this.item.cardTemplateRetro = file.name;
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        EduLabDataService.getAllProducts()
            .then(response => response.json())
            .then(data => {
                this.products = data;
            })
            .catch(e => {
                console.log(e);
            });
    },
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
