<template>
    <div>
        <div v-if="currentTemplate" class="card">
            <b-form @submit="updateTemplate">

                <b-form-group id="edulabProductId" label="Prodotto EduLab" label-for="edulabProductId" description="">
                    <b-form-select v-model="currentTemplate.edulabProductId">
                        <option value="0">- Scegli un prodotto -</option>
                        <option v-for="product in products" v-bind:value="product.id" :key="product.id">
                            {{ product.name }}
                        </option>
                    </b-form-select>
                </b-form-group>


                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentTemplate.name"
                                  placeholder=""></b-form-input>
                </b-form-group>



                <b-form-row>
                    <b-col sm="4">
                        <b-form-group id="durationDays" label="Durata della licenza (giorni)" label-for="durationDays">
                            <b-form-input v-model="currentTemplate.durationDays"
                                          placeholder=""></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="8">
                        <b-form-group id="activate" label="Attivazione" label-for="activate" description="">
                            <b-form-select v-model="currentTemplate.activate">
                                <option value="onRegister">Al momento della registrazione</option>
                                <option value="onCreate">Alla creazione</option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>


                <b-form-group id="activationLimitDays" label="Limite di attivazione (giorni)" label-for="activationLimitDays" description="Specificare entro quanti giorni è possibile attivare la licenza. Il valore 0 equivale a nessun limite.">
                    <b-form-input v-model="currentTemplate.activationLimitDays"
                                  placeholder=""></b-form-input>
                </b-form-group>

                <b-row style="background-color: #eeeeee; padding: 10px">
                    <b-col sm="12"><b>Immagini per la tessera</b></b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setFront($event)" :current-image="currentFrontImage" :label="'FRONTE'"/>
                    </b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setRetro($event)" :current-image="currentRetroImage" :label="'RETRO'"/>
                    </b-col>
                </b-row>

                <b-form-group id="termsOfUse" label="Condizioni generali di utilizzo" label-for="termsOfUse">
                    <editor-quill v-model="currentTemplate.termsOfUse" />
                </b-form-group>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateTemplate" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>

                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteTemplate" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Template...' }}</p>
        </div>
    </div>
</template>

<script>
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import UploadImage from "@/components/upload/UploadImage";
import UploadDataService from "@/components/upload/UploadDataService";
import EditorQuill from "@/components/utility/EditorQuill";

export default {
    name: "product-license-template-edit",
    components: { UploadImage, EditorQuill },
    data() {
        return {
            currentTemplate: null,
            currentFrontImage: null,
            currentRetroImage: null,
            products: [],
            message: '',
            errorMsg: '',
        };
    },
    props: ["templateId"],
    methods: {
        getTemplate(id) {
            ProductLicenseTemplateDataService.get(id)
                .then(response => {
                    this.currentTemplate = response.data;
                    if (this.currentTemplate.cardTemplateFront) UploadDataService.get(this.currentTemplate.cardTemplateFront)
                        .then(response => {
                            this.currentFrontImage = response.data.encoded;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });

                    if (this.currentTemplate.cardTemplateRetro) UploadDataService.get(this.currentTemplate.cardTemplateRetro)
                        .then(response => {
                            this.currentRetroImage = response.data.encoded;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateTemplate() {
            ProductLicenseTemplateDataService.update(this.currentTemplate.id, this.currentTemplate)
                .then(response => {
                    this.message = 'The class was updated successfully!';
                    this.$emit('updated-template', this.currentTemplate);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteTemplate() {
            if(confirm(`Vuoi cancellare l'aula ${this.currentTemplate.name}?`)) {
                ProductLicenseTemplateDataService.delete(this.currentTemplate.id)
                    .then(response => {
                        this.$emit('deleted-template');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        setFront(file) {
            this.currentTemplate.cardTemplateFront = file.name;
        },
        setRetro(file) {
            this.currentTemplate.cardTemplateRetro = file.name;
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getTemplate(this.templateId);
        EduLabDataService.getAllProducts()
            .then(response => response.json())
            .then(data => {
                this.products = data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
