<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Licenze</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentTeacher = null">
                <b-icon icon="plus"/> Aggiungi un template di licenza
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Durata</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in templates"
                            :key="item.id"
                            @click="setActiveTemplate(item)"
                        >
                            <td>{{ item.name }}</td>
                            <td>{{ item.durationDays }} giorni</td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.templates.length == 0">Ancora nessun template di licenze inserito</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <product-license-template-view :template="currentTemplate" :key="currentTemplate.id"
                                                   v-on:updated-template="refreshCurrentTemplate($event)"
                                                   v-on:edit-template="setEdit()"
                                                   v-on:generate-license="setGenerate()"
                                                   v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <product-license-template-create
                        v-on:created-template="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>


            <div class="editor editor-edit" :class="{ 'opened': (currentId > 0 && edit) }" v-if="currentId > 0">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <product-license-template-edit v-if="edit" :template-id="currentTemplate.id" :key="currentTemplate.id"
                                               v-on:updated-template="refreshCurrentTemplate($event)"
                                               v-on:deleted-template="refreshList()"
                                               v-on:closed-editor="closeEditor()"/>
            </div>

            <div class="editor editor-generate" :class="{ 'opened': (currentId > 0 && generate) }" v-if="currentId > 0">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <product-license-generate v-if="generate" :template="currentTemplate" :key="currentTemplate.id"
                                               v-on:generated-licenses="closeEditor"
                                               v-on:closed-editor="closeEditor()"/>
            </div>

        </div>
    </div>

</template>

<script>
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import ProductLicenseTemplateCreate from "@/components/product_license_template/ProductLicenseTemplateCreate";
import ProductLicenseTemplateEdit from "@/components/product_license_template/ProductLicenseTemplateEdit";
import ProductLicenseTemplateView from "@/components/product_license_template/ProductLicenseTemplateView";
import ProductLicenseGenerate from "@/components/product_license/ProductLicenseGenerate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "product-license-template-index",
    components: {ProductLicenseTemplateEdit, ProductLicenseTemplateCreate, ProductLicenseTemplateView, ProductLicenseGenerate, SearchBar},
    data() {
        return {
            templates: [],
            currentTemplate: null,
            currentId: -1,
            edit: false,
            generate: false
        };
    },
    methods: {
        retrieveTemplates() {
            ProductLicenseTemplateDataService.getAll()
                .then(response => {
                    this.templates = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveTemplates();
            this.currentTemplate = null;
            this.currentId = -1;
        },

        refreshCurrentTemplate(itemTemplate) {
            this.templates.forEach(function (item) {
                if (item.id == itemTemplate.id) {
                    item.name = itemTemplate.name;
                    item.durationDays = itemTemplate.durationDays;
                    item.activate = itemTemplate.activate;
                    item.activationLimitDays = itemTemplate.activationLimitDays;
                    item.edulabProductId = itemTemplate.edulabProductId;
                    item.termsOfUse = itemTemplate.termsOfUse;
                    item.cardTemplateFront = itemTemplate.cardTemplateFront;
                    item.cardTemplateRetro = itemTemplate.cardTemplateRetro;
                }
            });
            this.currentTemplate = itemTemplate;
            this.edit = false
        },

        setActiveTemplate(item) {
            this.currentTemplate = item;
            this.currentId = item.id;
            this.edit = false;
        },

        setEdit() {
            this.edit = true;
        },
        setGenerate() {
            this.generate = true;
        },

        removeAllTemplates() {
            ProductLicenseTemplateDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentTemplate = null;
            this.currentId = -1;
            ProductLicenseTemplateDataService.findByName(name)
                .then(response => {
                    this.templates = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            if (this.edit == true) {
                this.edit = false
                return;
            }
            if (this.generate == true) {
                this.generate = false
                return;
            }
            this.currentTemplate = null;
            this.currentId = -1;
        },
    },
    mounted() {
        this.retrieveTemplates();
    }
};
</script>

<style scoped>
.editor-edit.opened {
    top: 20vh;
}
.editor-edit {
    height: 80vh;
}
.editor-generate.opened {
    top: 30vh;
}
.editor-generate {
    height: 70vh;
}
</style>
